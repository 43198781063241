import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";
import Profile from "./Profile";
import '../index.css';

function AuthenticationButtons() {
    const { isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return <div className="top-bar">Loading...</div>;
    }

    return (
        <div className="top-bar">
            <div className="profile-details">
                {isAuthenticated && <Profile />}
            </div>
            <div className="auth__buttons">
                {isAuthenticated ? <LogoutButton /> : <LoginButton />}
            </div>
        </div>
    );
}

export default AuthenticationButtons;