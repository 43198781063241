import Account from "./components/account";
import TaskList from "./components/TaskList";

/**
 * Simple task tracker app.
 * (For training purposes)
 */
function App() {
  
  return (
    <>
      <Account />
      <TaskList />
    </>
  );
}

export default App;
