import React, { useState } from 'react';
import { TextField, Button, Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';



function TaskForm({ addTask }) {
  const [text, setText] = useState('');
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const handleSubmit = (e) => {
    e.preventDefault();
    addTask(text);
    setText('');
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%', mt: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={9} sm={10}>
          <TextField
            label="Task Name"
            variant="outlined"
            fullWidth
            size="small"
            value={text}
            onChange={(e) => setText(e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={3} sm={2}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            className="task-form__button"
          >
            {/* Conditionally render the button content based on screen size */}
            {isSmallScreen ? <AddIcon /> : <><AddIcon sx={{ mr: 1 }} /> Add Task</>}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default TaskForm;