// Importing necessary React hooks and components from 'react' and various UI components from '@mui/material'
import React, { useState, useEffect } from 'react';
import {
  Box, Card, CardContent, Typography, List, ListItem, ListItemIcon,
  ListItemText, IconButton, Checkbox, Chip, Divider
} from '@mui/material';

// Importing icons from '@mui/icons-material' for use within the UI
import DeleteIcon from '@mui/icons-material/Delete';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// Importing a custom TaskForm component for adding tasks
import TaskForm from './TaskForm';
// Importing CSS for styling
import '../index.css';
import { useAuth0 } from "@auth0/auth0-react";
import * as TaskApi from '../utils/TasksApi'

// TaskList component declaration
function TaskList () {
  const { user, isAuthenticated, isLoading } = useAuth0();

  // State hook for managing tasks list, initialized with default tasks
  const [tasks, setTasks] = useState([
    { id: 1, text: 'Click here to edit', completed: false, editMode: false },
    { id: 3, text: 'Click here to edit', completed: true, editMode: false },
  ]);

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        if (!isLoading) {
          const loadedTasks = await TaskApi.getTasks(isAuthenticated, user?.sub);
          setTasks(loadedTasks);
        }
      } catch (err) {
        console.error(err)
      } finally {
      }
    };

    fetchTasks();
  }, [isAuthenticated, user?.sub, isLoading]);

  // Function to toggle the completion status of a task
  const handleTaskClick = (taskId) => {
    const updatedTasks = tasks.map(task => {
      if (task.id === taskId) {
        const newTask = { ...task, completed: !task.completed };
        TaskApi.setTask(user?.sub, newTask, "PUT")
        return newTask;
      }
      return task;
    });
    setTasks(updatedTasks);
  };

  // Function to delete a task from the list
  const handleTaskDelete = (taskId) => {
    const updatedTasks = tasks.filter(task => task.id !== taskId);
    TaskApi.setTask(user?.sub, {id: taskId}, "DELETE")
    setTasks(updatedTasks);
  };

  // Function to add a new task with a unique ID and specified text
  // Add a new task
  const addTask = (text) => {
    const newTask = {
      id: crypto.randomUUID(),
      text: text,
      completed: false,
    };
    setTasks([...tasks, newTask]);
    TaskApi.setTask(user?.sub, newTask, "POST")
  };
  // Enable edit mode for a task
  const handleEditClick = (taskId) => {
    const updatedTasks = tasks.map(task => {
      if (task.id === taskId) {
        return { ...task, editMode: !task.editMode };
      }
      return task;
    });
    setTasks(updatedTasks);
  };
  // Update task text
  const handleTaskUpdate = (taskId, newText) => {
  // Saves the new text for the task and exits edit mode
    const updatedTasks = tasks.map(task => {
      if (task.id === taskId) {
        const newTask = { ...task, text: newText, editMode: false };
        TaskApi.setTask(user?.sub, newTask, "PUT")
        return newTask;
      }
      return task;
    });
    setTasks(updatedTasks);
  };

  // Separating tasks into "To Do" and "Done" categories
  const todoTasks = tasks.filter(task => !task.completed);
  const doneTasks = tasks.filter(task => task.completed);

  // Function to render tasks with dividers between them
  
  const renderTasksWithDividers = (taskList) => {
    return taskList.map((task, index) => (
      <React.Fragment key={task.id}>
        <ListItem
          secondaryAction={
            <IconButton edge="end" onClick={() => handleTaskDelete(task.id)}>
              <DeleteIcon />
            </IconButton>
          }
        >
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={task.completed}
              tabIndex={-1}
              disableRipple
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleIcon style={{ color: '#4caf50' }} />}
              onClick={() => handleTaskClick(task.id)}
            />
          </ListItemIcon>
          {task.editMode ? (
            <input
              type="text"
              defaultValue={task.text}
              onBlur={(e) => handleTaskUpdate(task.id, e.target.value)}
              autoFocus
            />
          ) : (
            <ListItemText
              primary={task.text}
              onClick={() => handleEditClick(task.id)}
              primaryTypographyProps={{
                style: {
                  color: task.completed ? 'lightgrey' : 'inherit',
                  cursor: 'pointer',
                }
              }}
            />
          )}
        </ListItem>
        {index !== taskList.length - 1 && <Divider />}
      </React.Fragment>
    ));
  };
  
  // Rendering the task tracker UI with "TO DO" and "DONE" sections
  return (
    <Box mx={5} my={5}>
      <Typography variant='h4' gutterBottom>
        <div className="title">Task Tracker</div>
      </Typography>
      <TaskForm addTask={addTask} />
      <Box mt={4}>
        <Card variant="outlined">
          <CardContent>
            <Chip label="TO DO" className="chip--todo" />
            <List>
              {renderTasksWithDividers(todoTasks)}
            </List>
          </CardContent>
        </Card>
      </Box>
      <Box mt={4}>
        <Card variant="outlined">
          <CardContent>
            <Chip label="DONE" className="chip--done" />
            <List>
              {renderTasksWithDividers(doneTasks)}
            </List>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}

export default TaskList;