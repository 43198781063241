
const apiUrl = "https://tasklist.medhat-adolf.workers.dev/todos"

export async function getTasks(isAuthenticated, userId) {
    if (!isAuthenticated) {
        return [
            { id: 1, text: 'Click here to edit', completed: false, editMode: false },
            { id: 3, text: 'Click here to edit', completed: true, editMode: false },
        ]
    }
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
    };

    try {
        const response = await fetch(`${apiUrl}?user_id=${userId}`, requestOptions);
        if (!response.ok) throw new Error('Network response was not ok.');
        const result = await response.json(); // Assuming the server responds with JSON
    
        for (let i = 0; i < result.length; i++) {
            result[i]["completed"] = result[i]["completed"] === 1;
        }
        return result; // This returns the parsed JSON object directly
    } catch (error) {
        throw error; // Rethrow the error to be handled where the function is called
    }
}

export async function setTask(userId, body, verb) {
    if (isNullOrEmpty(userId)) {
        return {}
    }
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({...body, user_id:userId, completed: body["completed"] ? 1 : 0});

    const requestOptions = {
        method: verb,
        headers: myHeaders,
        body: raw,
        redirect: "follow"
    };

    fetch(`${apiUrl}`, requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.error(error));
}

function isNullOrEmpty(value) {
    // Check for null or undefined
    if (value == null) {
      return true;
    }
  
    // Check for an empty string
    if (typeof value === 'string' && value.trim().length === 0) {
      return true;
    }
  
    // Check for an empty array
    if (Array.isArray(value) && value.length === 0) {
      return true;
    }
  
    // Check for an object with no own enumerable properties
    if (typeof value === 'object' && Object.keys(value).length === 0) {
      return true;
    }
  
    // If none of the above, the value is not considered null or empty
    return false;
  }
  