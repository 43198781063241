import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import '../index.css';

const Profile = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    isAuthenticated && (
      <>
        <div className="profile__details">
          <img src={user.picture} alt={user.name} className="profile__pic" />
          <span className="profile__name">{user.name}</span>
        </div>
      </>
    )
  );
};

export default Profile;